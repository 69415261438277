import React, {useEffect, useState} from 'react';
import axios from "axios";

import "./Attachment.css";
import {Box, Button, Grid, Paper, Typography} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import {styled} from "@mui/material/styles";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import * as PropTypes from "prop-types";
import UploadComponent from "./UploadComponent";

const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

function DeleteComponent(props) {
    return (
        <div>
            {!(props.readonly) &&
                (<Item onClick={props.onClick}>
                    <Button sx={{minHeight: "20px", maxHeight: "20px"}}>
                        <DeleteForeverIcon/>
                        Hapus
                    </Button>
                </Item>)
            }
        </div>
    );
}

DeleteComponent.propTypes = {onClick: PropTypes.func};

function Attachment({id, datasource, schoolid, readonly, setAttachmentCount}) {
    // id = em1id, im2id, audit1id, audit2id.
    const [data, setData] = useState(null); // file list
    const [fileCount, setFileCount] = useState(1);

    // get list of attachment
    useEffect(() => {
            axios.get(process.env.REACT_APP_SERVER + "/attachment?datasource=" + datasource + "&id=" + id.toString())
                .then((response) => {
                    // console.log(response.data.data);
                    // adding key value, no counter from 0 for indexing
                    let tempData = response.data.data;
                    let i = -1;
                    tempData.map(singleData => {
                        i = i + 1;
                        return (singleData["no"] = i);
                    });
                    setData(tempData);
                    // patch update using em1id score
                })
                .catch((err) => console.log(err));
        }
        , [id, fileCount]);

    // call for calculate score
    useEffect(() => {
        if (!data) return;
        setAttachmentCount(data.length);
    }, [data, setAttachmentCount]);

    const handleDelete = (id) => {
        // console.log(id);
        axios.delete(process.env.REACT_APP_SERVER + "/attachment?datasource=" + datasource + "&id=" + id.toString())
            .then((response) => {
                console.log("success delete");
                setFileCount(fileCount + 1);
            })
            .catch((err) => console.log(err));
    };

    const handleDownload = (id, no) => {
        let url = process.env.REACT_APP_SERVER + "/attachment/download?datasource=" + datasource + "&id=" + id.toString();
        const link = document.createElement("a");
        link.target = "_blank";
        link.download = data[no].filename;
        axios
            .get(url, {
                responseType: "blob",
            })
            .then((res) => {
                link.href = URL.createObjectURL(
                    new Blob([res.data])
                );
                link.click();
            });
        // .catch((err) => console.log(err));
    };

    return (
        <div className={"attachment"}>
            <Typography variant="h6">Lampiran</Typography>
            {data && data.map((singleFile) => {
                    return (
                        <div key={singleFile.id}>
                            <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "10px"}}>
                                <Grid container spacing={1}>
                                    <Grid item xs={2}>
                                        <Item onClick={() => handleDownload(singleFile.id, singleFile.no)}>
                                            <Button sx={{
                                                minHeight: "20px", maxHeight: "20px",
                                                borderRadius: "0px", boxShadow: "none"
                                            }}>
                                                <DownloadIcon/>
                                                Unduh
                                            </Button>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Item
                                            sx={{textAlign: "left"}}>{singleFile.description}</Item>
                                    </Grid>
                                    <Grid item xs={2}>
                                        {/* TODO: if readonly, hide bottom*/}
                                        <DeleteComponent onClick={() => handleDelete(singleFile.id)} readonly={readonly}/>
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                    );
                }
            )
            }
            <p></p>
            {/* if readonly, don't show uploadcomponent */}
            {!readonly &&
                <UploadComponent borderColor={(theme) => theme.palette.primary.main}
                                 id={id} datasource={datasource} schoolid={schoolid}
                                 setFileCount={setFileCount} fileCount={fileCount}/>

            }
        </div>
    );
}

export default Attachment;