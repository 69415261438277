import React from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import {useFetch} from "../../hooks/useFetch";
// import * as React from "react";
import Button from "@mui/material/Button";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
// import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import "./SchoolQuestions.css";
import {useSchoolInfo} from "../../hooks/useSchoolInfo";
import useUser from "../../user/use";
import {Grid, Paper} from "@mui/material";
import SchoolWidget from "../../components/SchoolWidget";
import {useEffect, useState} from "react";
import axios from "axios";

// ItemBig for SRAMandiri & PRAPMandiri
const ItemBig = (props) => {
    return (
        <Box sx={{
            width: '50px',
            backgroundColor: `${props.backgroundColor}`,
            color: "white",
            marginLeft: "2px",
            marginRight: "2px",
            // margin: "auto",
            textAlign: "center",
            fontSize: "14px",
            paddingTop: "4px",
            paddingRight: "2px",
            paddingBottom: "4px",
            paddingLeft: "2px",
        }}>
            {props.children}
        </Box>
    );
};

const Item = (props) => {
    return (
        <Box sx={{
            width: '50px',
            backgroundColor: `${props.backgroundColor}`,
            color: "white",
            marginLeft: "2px",
            marginRight: "2px",
            // margin: "auto",
            textAlign: "center",
            fontSize: "10px",
            paddingTop: "4px",
            paddingRight: "2px",
            paddingBottom: "4px",
            paddingLeft: "2px",
        }}>
            {props.children}
        </Box>
    );
};

const BigBoxValue = ({value, content = ""}) => {
    // console.log(value);
    if (value === "-") {
        return (
            <ItemBig backgroundColor="#95A7C0">{content}</ItemBig>
        );
    } else {
        return (
            <ItemBig backgroundColor="#006638">{content}</ItemBig>
        );
    }
};

const SmallBoxValue = ({value, content = ""}) => {
    // console.log(value);
    if (value === "-") {
        return (
            <Item backgroundColor="#95A7C0">{content}</Item>
        );
    } else {
        return (
            <Item backgroundColor="#006638">{content}</Item>
        );
    }
};

// will return color value which depends of value and sMaxx
const colorValue = ({value, sMax}) => {
    if (value === "Ya" || sMax === "Tidak") {
        return "#ffffff";
    }
    if (value === "Tidak" && sMax === "Ya") {
        return "#B88917";
        // return "#B03A2E";
    }
    if (value === "-" && sMax === "Ya") {
        return "#95A7C0";
    }
}

const SmallBoxStd = ({value, sMax, content = ""}) => {
    // if not need, or meet minimum requirement
    if (value === "Ya" || sMax === "Tidak") {
        return (
            <Item backgroundColor="#006638">{content}</Item>
        );
    }
    // if need but failed
    if (value === "Tidak" && sMax === "Ya") {
        return (
            <Item backgroundColor="#914E75">{content}</Item>
        );
    }
    // if needed, but still not have value
    if (value === "-" && sMax === "Ya") {
        return (
            <Item backgroundColor="#95A7C0">{content}</Item>
        );
    }
};

// for SRA and LPKRA
// change this for temporary for SRA Mandiri
const MyitemSRAMandiri = ({
                              no,
                              noshow,
                              summary,
                              em1 = "-",
                              audit1 = "-",
                              audit2 = "-",
                              em2 = "-",
                              audit3 = "-",
                              max = "-",
                              link,
                              sEm1 = "-",
                              sAudit1 = "-",
                              sAudit2 = "-",
                              sEm2 = "-",
                              sAudit3 = "-",
                              sMax = "-",
                              showAudit1 = true,
                              appType = "SRAMandiri"
                          }) => {
    const intToChar = (n) => {
        var chr = String.fromCharCode(96 + n);
        return chr;
    };
    return (
        <>
            <Paper elevation={0}
                   sx={{
                       padding: "10px",
                       marginLeft: "15px",
                       marginRight: "15px",
                       marginTop: "10px",
                       marginBottom: "10px",
                       backgroundColor: colorValue({value: sEm1, sMax: sMax}),
                       display: "flex",
                       flexDirection: "row",
                   }}>
                {/*{colorValue({value: sEm1, sMax: sMax})}*/}

                {(sMax === "Ya") &&
                    <Box sx={{
                        width: '60px', textAlign: 'center', marginLeft: '-15px', marginRight: '4px',
                        backgroundColor: 'red', color: 'white', marginTop: '-10px', marginBottom: '-10px',
                        display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: 'stretch'
                    }}>
                        {noshow}
                    </Box>
                }
                {(sMax !== "Ya") &&
                    <Box sx={{
                        width: '60px', textAlign: 'center', marginLeft: '-15px', marginRight: '4px',
                        marginTop: '-10px', marginBottom: '-10px',
                        display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: 'stretch'
                    }}>
                        {noshow}
                    </Box>
                }
                <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>


                    <Box sx={{width: "680px", paddingLeft: "5px", paddingRight: "5px"}}>
                        {summary}
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <Box sx={{display: 'flex', flexDirection: 'row', marginTop: '0px', marginBottom: '0px'}}>
                            <BigBoxValue value={em1} content={`EM1: ${em1}`}></BigBoxValue>
                            <ItemBig backgroundColor="#006638">Maks: {max}</ItemBig>
                        </Box>
                    </Box>
                    <Box>
                        <Link to={link} style={{textDecoration: 'none'}}>
                            <Button variant="contained" size="small"
                                    sx={{
                                        marginLeft: "8px", marginRight: "8px", textDecoration: 'none',
                                        borderRadius: "0px", boxShadow: "none"
                                    }}>Lihat</Button>
                        </Link>
                    </Box>
                </Box>
            </Paper>
        </>
    );
};

const MyitemSRA = ({
                       no,
                       noshow,
                       summary,
                       em1 = "-",
                       audit1 = "-",
                       audit2 = "-",
                       em2 = "-",
                       audit3 = "-",

                       em3 = "-",
                       audit4 = "-",

                       max = "-",
                       link,
                       sEm1 = "-",
                       sAudit1 = "-",
                       sAudit2 = "-",
                       sEm2 = "-",
                       sAudit3 = "-",

                       sEm3 = "-",
                       sAudit4 = "-",

                       sMax = "-",
                       showAudit1 = true,
                       appType = "SRA"
                   }) => {
    const intToChar = (n) => {
        var chr = String.fromCharCode(96 + n);
        return chr;
    };
    return (
        <Paper elevation={0}
               sx={{padding: "10px", marginLeft: "15px", marginRight: "15px", marginTop: "10px", marginBottom: "10px"}}>
            {/*{appType}*/}
            {/*{em3}*/}
            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <Box sx={{width: '60px', textAlign: 'center', marginLeft: '4px', marginRight: '4px'}}>
                    {/*{intToChar(no)}*/}
                    {noshow}
                </Box>
                <Box sx={{width: "680px", paddingLeft: "5px", paddingRight: "5px"}}>
                    {summary}
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                    <Box sx={{display: 'flex', flexDirection: 'row', marginTop: '2px', marginBottom: '2px'}}>
                        {/* operator sra show, operator pisa hidden */}
                        {/*<Item backgroundColor="#006638">EM1: {em1}</Item>*/}
                        <SmallBoxValue value={em1} content={`EM1: ${em1}`}></SmallBoxValue>
                        {(appType === "PISA") && showAudit1 &&
                            // <Item backgroundColor="#006638">AU1: {audit1}</Item>
                            <SmallBoxValue value={audit1} content={`AU1: ${audit1}`}></SmallBoxValue>
                        }
                        {(appType === "SRA" || appType === "LPKRA" || appType === "LPLRA" || appType === "PRAP") &&
                            // <Item backgroundColor="#006638">AU1: {audit1}</Item>
                            <SmallBoxValue value={audit1} content={`AU1: ${audit1}`}></SmallBoxValue>
                        }
                        {/* operator pisa show */}
                        {(appType === "PISA") &&
                            // <Item backgroundColor="#006638">AUDIT: {audit2}</Item>
                            <SmallBoxValue value={audit2} content={`AUDIT: ${audit2}`}></SmallBoxValue>
                        }
                        {/*<Item backgroundColor="#006638">EM2: {em2}</Item>*/}
                        <SmallBoxValue value={em2} content={`EM2: ${em2}`}></SmallBoxValue>
                        {/*<Item backgroundColor="#006638">AUF: {audit3}</Item>*/}
                        <SmallBoxValue value={audit3} content={`AU2: ${audit3}`}></SmallBoxValue>

                        {appType === "LPLRA" &&
                            <>
                                <SmallBoxValue value={em3} content={`EM3: ${em3}`}></SmallBoxValue>
                                {/*<Item backgroundColor="#006638">AUF: {audit3}</Item>*/}
                                <SmallBoxValue value={audit4} content={`AUF: ${audit4}`}></SmallBoxValue>
                            </>
                        }

                        <Item backgroundColor="#006638">Maks: {max}</Item>
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'row', marginTop: '2px', marginBottom: '2px'}}>
                        {/*<Item backgroundColor="#006638">Std: {sEm1}</Item>*/}
                        <SmallBoxStd value={sEm1} sMax={sMax} content={`Std1: ${sEm1}`}></SmallBoxStd>
                        {/* operator sra show, operator pisa hidden */}
                        {(appType === "PISA") && showAudit1 &&
                            // <Item backgroundColor="#006638">Std: {sAudit1}</Item>
                            <SmallBoxStd value={sAudit1} sMax={sMax} content={`Std2: ${sAudit1}`}></SmallBoxStd>
                        }
                        {(appType === "SRA" || appType === "LPKRA" || appType === "LPLRA" || appType === "PRAP") &&
                            // <Item backgroundColor="#006638">Std: {sAudit1}</Item>
                            <SmallBoxStd value={sAudit1} sMax={sMax} content={`Std3: ${sAudit1}`}></SmallBoxStd>
                        }
                        {/* operator pisa show */}
                        {(appType === "PISA") &&
                            // <Item backgroundColor="#006638">Std: {sAudit2}</Item>
                            <SmallBoxStd value={sAudit2} sMax={sMax} content={`Std4: ${sAudit2}`}></SmallBoxStd>
                        }
                        {/*<Item backgroundColor="#006638">Std: {sEm2}</Item>*/}
                        <SmallBoxStd value={sEm2} sMax={sMax} content={`Std5: ${sEm2}`}></SmallBoxStd>
                        {/*<Item backgroundColor="#006638">Std: {sAudit3}</Item>*/}
                        <SmallBoxStd value={sAudit3} sMax={sMax} content={`Std6: ${sAudit3}`}></SmallBoxStd>
                        {appType === "LPLRA" &&
                            <>
                                <SmallBoxStd value={sEm3} sMax={sMax} content={`Std5: ${sEm3}`}></SmallBoxStd>
                                {/*<Item backgroundColor="#006638">Std: {sAudit3}</Item>*/}
                                <SmallBoxStd value={sAudit4} sMax={sMax} content={`Std6: ${sAudit4}`}></SmallBoxStd>
                            </>
                        }

                        <Item backgroundColor="#006638">Std6: {sMax}</Item>
                    </Box>
                </Box>
                <Box>
                    <Link to={link} style={{textDecoration: 'none'}}>
                        <Button variant="contained" size="small"
                                sx={{
                                    marginLeft: "8px", marginRight: "8px", textDecoration: 'none',
                                    borderRadius: "0px", boxShadow: "none"
                                }}>Lihat</Button>
                    </Link>
                </Box>
            </Box>
        </Paper>
    );
};

const MyitemPISA = ({
                        no,
                        noshow,
                        summary,
                        em1 = "-",
                        audit1 = "-",
                        audit2 = "-",
                        em2 = "-",
                        audit3 = "-",
                        max = "-",
                        link,
                        sEm1 = "-",
                        sAudit1 = "-",
                        sAudit2 = "-",
                        sEm2 = "-",
                        sAudit3 = "-",
                        sMax = "-",
                        showAudit1 = true,
                        appType = "SRA"
                    }) => {
    const intToChar = (n) => {
        var chr = String.fromCharCode(96 + n);
        return chr;
    };
    return (
        <Paper elevation={0}
               sx={{padding: "10px", marginLeft: "15px", marginRight: "15px", marginTop: "10px", marginBottom: "10px"}}>
            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <Box sx={{width: '60px', textAlign: 'center', marginLeft: '4px', marginRight: '4px'}}>
                    {/*{intToChar(no)}*/}
                    {noshow}
                </Box>
                <Box sx={{width: "680px", paddingLeft: "5px", paddingRight: "15px"}}>
                    {summary}
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                    <Box sx={{display: 'flex', flexDirection: 'row', marginTop: '2px', marginBottom: '2px'}}>
                        {/* operator sra show, operator pisa hidden */}
                        {/*<Item backgroundColor="#006638">EM1: {em1}</Item>*/}
                        <Typography variant="h7"
                                    sx={{
                                        fontSize: '16px',
                                        marginLeft: '6px',
                                        marginRight: '6px'
                                    }}>EM1:{em1}</Typography>
                        {(appType === "PISA") && showAudit1 &&
                            // <Item backgroundColor="#006638">AU1: {audit1}</Item>
                            <Typography variant="h7"
                                        sx={{
                                            fontSize: '16px',
                                            marginLeft: '6px',
                                            marginRight: '6px'
                                        }}>AU1:{audit1}</Typography>
                        }
                        {(appType === "SRA" || appType === "LPKRA" || appType === "LPLRA" || appType === "PRAP") &&
                            // <Item backgroundColor="#006638">AU1: {audit1}</Item>
                            <Typography variant="h7"
                                        sx={{
                                            fontSize: '16px',
                                            marginLeft: '6px',
                                            marginRight: '6px'
                                        }}>AU1:{audit1}</Typography>
                        }
                        {/* operator pisa show */}
                        {/* bug: untuk sementara dihidden dulu */}
                        {/*{(appType === "PISA") &&*/}
                        {/*  // <Item backgroundColor="#006638">AUDIT: {audit2}</Item>*/}
                        {/*  <Typography variant="h7"*/}
                        {/*              sx={{fontSize: '16px', marginLeft: '6px', marginRight: '6px'}}>AUDIT:{audit2}</Typography>*/}
                        {/*}*/}
                        {/*<Item backgroundColor="#006638">EM2: {em2}</Item>*/}
                        <Typography variant="h7"
                                    sx={{
                                        fontSize: '16px',
                                        marginLeft: '6px',
                                        marginRight: '6px'
                                    }}>EM2:{em2}</Typography>
                        {/*<Item backgroundColor="#006638">AUF: {audit3}</Item>*/}
                        <Typography variant="h7"
                                    sx={{
                                        fontSize: '16px',
                                        marginLeft: '6px',
                                        marginRight: '6px'
                                    }}>AUF:{audit3}</Typography>
                        {/*<Item backgroundColor="#006638">Maks: {max}</Item>*/}
                        <Typography variant="h7"
                                    sx={{
                                        fontSize: '16px',
                                        marginLeft: '6px',
                                        marginRight: '6px'
                                    }}>Maksimum:{max}</Typography>
                    </Box>
                    {/*<Box sx={{display: 'flex', flexDirection: 'row', marginTop: '2px', marginBottom: '2px'}}>*/}
                    {/*  <Item backgroundColor="#006638">Std: {sEm1}</Item>*/}
                    {/*  /!* operator sra show, operator pisa hidden *!/*/}
                    {/*  {(appType === "PISA") && showAudit1 &&*/}
                    {/*    <Item backgroundColor="#006638">Std: {sAudit1}</Item>*/}
                    {/*  }*/}
                    {/*  {appType === "SRA" &&*/}
                    {/*    <Item backgroundColor="#006638">Std: {sAudit1}</Item>*/}
                    {/*  }*/}
                    {/*  /!* operator pisa show *!/*/}
                    {/*  {(appType === "PISA") &&*/}
                    {/*    <Item backgroundColor="#006638">Std: {sAudit2}</Item>*/}
                    {/*  }*/}
                    {/*  <Item backgroundColor="#006638">Std: {sEm2}</Item>*/}
                    {/*  <Item backgroundColor="#006638">Std: {sAudit3}</Item>*/}
                    {/*  <Item backgroundColor="#006638">Std: {sMax}</Item>*/}
                    {/*</Box>*/}
                </Box>
                <Box>
                    <Link to={link} style={{textDecoration: 'none'}}>
                        <Button variant="contained" size="small"
                                sx={{
                                    marginLeft: "8px", marginRight: "8px", textDecoration: 'none',
                                    borderRadius: "0px", boxShadow: "none"
                                }}>Lihat</Button>
                    </Link>
                </Box>
            </Box>
        </Paper>
    );
};

const Myitem = ({
                    no,
                    noshow,
                    summary,
                    em1 = "-",
                    audit1 = "-",
                    audit2 = "-",
                    em2 = "-",
                    audit3 = "-",

                    em3 = "-",
                    audit4 = "-",

                    max = "-",
                    link,
                    sEm1 = "-",
                    sAudit1 = "-",
                    sAudit2 = "-",
                    sEm2 = "-",
                    sAudit3 = "-",

                    sEm3 = "-",
                    sAudit4 = "-",

                    sMax = "-",
                    showAudit1 = true,
                    appType = "SRA"
                }) => {
    return (
        <div>
            {(appType === "SRA" || appType === "LPKRA" || appType === "PRAP") &&
                <MyitemSRA no={no} noshow={noshow} summary={summary}
                           em1={em1} audit1={audit1} audit2={audit2} em2={em2} audit3={audit3}
                           max={max} link={link} sEm1={sEm1} sAudit1={sAudit1} sAudit2={sAudit2} sEm2={sEm2}
                           sAudit3={sAudit3} sMax={sMax} showAudit1={showAudit1}
                           appType={appType}>
                </MyitemSRA>
            }
            {appType === "LPLRA" &&
                <MyitemSRA no={no} noshow={noshow} summary={summary}
                           em1={em1} audit1={audit1} audit2={audit2} em2={em2} audit3={audit3}
                           em3={em3} audit4={audit4}

                           max={max} link={link}

                           sEm1={sEm1} sAudit1={sAudit1} sAudit2={sAudit2} sEm2={sEm2}
                           sAudit3={sAudit3}
                           sEm3={sEm3} sAudit4={sAudit4}

                           sMax={sMax} showAudit1={showAudit1}
                           appType={appType}>
                </MyitemSRA>
            }
            {(appType === "PISA") &&
                <MyitemPISA no={no} noshow={noshow} summary={summary}
                            em1={em1} audit1={audit1} audit2={audit2} em2={em2} audit3={audit3}
                            max={max} link={link} sEm1={sEm1} sAudit1={sAudit1} sAudit2={sAudit2} sEm2={sEm2}
                            sAudit3={sAudit3} sMax={sMax} showAudit1={showAudit1}
                            appType={appType}>
                </MyitemPISA>
            }
            {(appType === "SRAMandiri" || appType === "PRAPMandiri") &&
                <MyitemSRAMandiri no={no} noshow={noshow} summary={summary}
                                  em1={em1} audit1={audit1} audit2={audit2} em2={em2} audit3={audit3}
                                  max={max} link={link} sEm1={sEm1} sAudit1={sAudit1} sAudit2={sAudit2} sEm2={sEm2}
                                  sAudit3={sAudit3} sMax={sMax} showAudit1={showAudit1}
                                  appType={appType}>
                </MyitemSRAMandiri>
            }
        </div>
    );
};

export default function SchoolQuestions2() {
    const {categoryid, schoolid} = useParams();
    const [categoryName, setCategoryName] = useState("");
    let history = useHistory();

    const appType = process.env.REACT_APP_APPTYPE;

    const {
        state: {userId, role, token},
    } = useUser();

    // typeEMAudit: 2 = only EM2 and Audit3, 3 = EM3 and Audit4
    let typeEMAudit = "2";
    if (appType === "LPLRA") {
        typeEMAudit = "3";
    }
    // get the data
    const url = process.env.REACT_APP_SERVER + "/schoolquestionsmulti?categoryid=" + categoryid + "&schoolid=" + schoolid + "&typeemaudit=" + typeEMAudit;
    const {data, isPending, error} = useFetch(url);
    // console.log(data);

    // fetch data for schoolInfo (stage) and other info
    const {schoolInfo} = useSchoolInfo(schoolid);

    // const [data2, setData2] = useState();
    // useEffect(() => {
    //   axios.get(process.env.REACT_APP_SERVER + "/categoryName", {params: {formid}})
    //     .then(response => {
    //         setData2(response.data.data[0]);
    //       }
    //     )
    //     .catch(err => {
    //       console.log(err);
    //     });
    // }, [formid]);

    // let i = 0;

    // todo: add indikator info
    return (
        <div>
            {schoolInfo &&
                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                        </Grid>
                        <Grid item xs={4}>
                            <SchoolWidget schoolInfo={schoolInfo}></SchoolWidget>
                        </Grid>
                    </Grid>
                </div>
            }
            <div className="questions">
                {(schoolInfo && (process.env.REACT_APP_DEV == 1)) &&
                    <div>
                        schoolid:{schoolid} - stage:{schoolInfo.stage} - userid:{userId} - role:{role}
                    </div>
                }
                <Typography
                    variant="h5">{`INSTRUMEN STANDARDISASI ${process.env.REACT_APP_TITLE.toUpperCase()}`}</Typography>
                {schoolInfo &&
                    <Typography varian="h6">{schoolInfo.schoolTypeName}</Typography>
                }
                {data &&
                    <div>
                        <Typography variant="h6"
                                    gutterBottom={true}>{data.meta[0].category_name.toUpperCase()}</Typography>
                    </div>
                }
                {data &&
                    <div>
                        {/*<Typography variant="h6">INDIKATOR: {data.additionalData.categoryName}</Typography>*/}
                        {/*<Typography variant="body1" color="text.secondary">*/}
                        {/*  Sudah Memenuhi Minimum Standard: {data.additionalData.countMinStandard}*/}
                        {/*</Typography>*/}
                        {/*<Typography variant="body1" color="text.secondary">*/}
                        {/*  Pengisian: {data.additionalData.countFillQuestions} dari {data.additionalData.totalNumberQuestions} sudah*/}
                        {/*  disi*/}
                        {/*</Typography>*/}
                        {/*<Typography variant="body1" color="text.secondary">*/}
                        {/*  Nilai EM1: {data.additionalData.totalEm1Score}*/}
                        {/*</Typography>*/}
                        {/*<Typography variant="body1" color="text.secondary">*/}
                        {/*  Nilai Max: {data.additionalData.maxEm1Score}*/}
                        {/*</Typography>*/}
                    </div>
                }

                {error && <p className="error">{error}</p>}
                {isPending && <p className="loading">Loading...</p>}
                {data &&
                    <>
                        <Button variant="contained" sx={{borderRadius: "0px", boxShadow: "none"}}
                                onClick={() => history.goBack()}>Kembali</Button>
                        {/*&nbsp;*/}
                        {/*<Button variant="contained" sx={{borderRadius: "0px", boxShadow: "none"}}*/}
                        {/*        onClick={() => history.goBack()}>Toggle</Button>*/}
                    </>
                }

                <Stack direction="row" sx={{
                    flexWrap: "wrap"
                }}>
                    {/* stage = 1,2,3, operator */}
                    {data && schoolInfo && role == "operator" && (schoolInfo.stage === 1 || schoolInfo.stage === 2 || schoolInfo.stage === 3
                            || schoolInfo.stage === 10) &&
                        data.data.map((question) => {
                            return (
                                // this is depends on stage and role.
                                // check again for sra, min_score_normal or min_score_disaster

                                <div key={question.form_id}>
                                    {/*{(question.em_1_meet_standard === 0) &&*/}
                                    <Myitem noshow={question.noshow} summary={question.summary}
                                            em1={question.em_1_score}
                                            sEm1={question.em_1_meet_standard === 1 ? "Ya" : "Tidak"}
                                            max="5" sMax={question.min_score_normal > 0 ? "Ya" : "Tidak"}
                                            link={`/em1/${question.form_id}/${schoolid}`}
                                            showAudit1={false}
                                            appType={appType}
                                    > </Myitem>
                                    {/*}*/}
                                </div>
                            );
                        })
                    }

                    {/* province */}
                    {data && schoolInfo && role == "province" &&
                        data.data.map((question) => {
                            return (
                                // this is depends on stage and role.
                                // check again for sra, min_score_normal or min_score_disaster
                                <div key={question.form_id}>
                                    <Myitem noshow={question.noshow} summary={question.summary}
                                            em1={question.em_1_score}
                                            sEm1={question.em_1_meet_standard === 1 ? "Ya" : "Tidak"}
                                            max="5" sMax={question.min_score_normal > 0 ? "Ya" : "Tidak"}
                                            link={`/em1/${question.form_id}/${schoolid}`}
                                            showAudit1={false}
                                            appType={appType}
                                    > </Myitem>
                                </div>
                            );
                        })
                    }

                    {/* stage = 4,5, operator */}
                    {data && schoolInfo && role == "operator" && (schoolInfo.stage === 4 || schoolInfo.stage === 5) &&
                        data.data.map((question) => {
                            return (
                                // this is depends on stage and role.
                                // check again for sra, min_score_normal or min_score_disaster
                                <div key={question.form_id}>
                                    <Myitem noshow={question.noshow} summary={question.summary}
                                            em1={question.em_1_score}
                                            sEm1={question.em_1_meet_standard === 1 ? "Ya" : "Tidak"}
                                            audit1={question.audit_1_score}
                                            sAudit1={question.audit_1_meet_standard === 1 ? "Ya" : "Tidak"}
                                            audit2={question.audit_2_score}
                                            sAudit2={question.audit_2_meet_standard === 1 ? "Ya" : "Tidak"}
                                            em2={question.em_2_score}
                                            sEm2={question.em_2_meet_standard === 1 ? "Ya" : "Tidak"}
                                        // todo: check -> for normal stage
                                        //       audit3={question.audit_3_score}
                                        //       sAudit3={question.audit_3_meet_standard === 1 ? "Ya" : "Tidak"}
                                            max="5" sMax={question.min_score_normal > 0 ? "Ya" : "Tidak"}
                                            link={`/em1/${question.form_id}/${schoolid}`}
                                            appType={appType}
                                    > </Myitem>
                                </div>
                            );
                        })
                    }

                    {/* stage = 6, operator */}
                    {data && schoolInfo && role == "operator" && (schoolInfo.stage === 6) &&
                        data.data.map((question) => {
                            return (
                                // this is depends on stage and role.
                                // check again for sra, min_score_normal or min_score_disaster
                                <div key={question.form_id}>
                                    <Myitem noshow={question.noshow} summary={question.summary}
                                            em1={question.em_1_score}
                                            sEm1={question.em_1_meet_standard === 1 ? "Ya" : "Tidak"}
                                        // audit1={question.audit_1_score} sAudit1={question.audit_1_meet_standard === 1 ? "Ya" : "Tidak"}
                                            audit2={question.audit_2_score}
                                            sAudit2={question.audit_2_meet_standard === 1 ? "Ya" : "Tidak"}
                                            em2={question.em_2_score}
                                            sEm2={question.em_2_meet_standard === 1 ? "Ya" : "Tidak"}
                                            audit3={question.audit_3_score}
                                            sAudit3={question.audit_3_meet_standard === 1 ? "Ya" : "Tidak"}
                                            max="5" sMax={question.min_score_normal > 0 ? "Ya" : "Tidak"}
                                            link={`/em1/${question.form_id}/${schoolid}`}
                                            appType={appType}
                                    > </Myitem>
                                </div>
                            );
                        })
                    }

                    {/* stage = 7, operator */}
                    {data && schoolInfo && role == "operator" && (schoolInfo.stage === 7) &&
                        data.data.map((question) => {
                            return (
                                // this is depends on stage and role.
                                // check again for sra, min_score_normal or min_score_disaster
                                <div key={question.form_id}>
                                    <Myitem noshow={question.noshow} summary={question.summary}
                                            em1={question.em_1_score}
                                            sEm1={question.em_1_meet_standard === 1 ? "Ya" : "Tidak"}
                                            audit1={question.audit_1_score}
                                            sAudit1={question.audit_1_meet_standard === 1 ? "Ya" : "Tidak"}
                                            audit2={question.audit_2_score}
                                            sAudit2={question.audit_2_meet_standard === 1 ? "Ya" : "Tidak"}
                                            em2={question.em_2_score}
                                            sEm2={question.em_2_meet_standard === 1 ? "Ya" : "Tidak"}
                                        // todo: temporary show for perbaikan em2
                                            audit3={question.audit_3_score}
                                            sAudit3={question.audit_3_meet_standard === 1 ? "Ya" : "Tidak"}
                                            max="5" sMax={question.min_score_normal > 0 ? "Ya" : "Tidak"}
                                            link={`/em1/${question.form_id}/${schoolid}`}
                                            appType={appType}
                                    > </Myitem>
                                </div>
                            );
                        })
                    }

                    {/* stage = 11,12, operator */}
                    {data && schoolInfo && role == "operator" && (schoolInfo.stage === 11 || schoolInfo.stage === 12 || schoolInfo.stage === 13) && appType === "LPLRA" &&
                        data.data.map((question) => {
                            return (
                                // this is depends on stage and role.
                                // check again for sra, min_score_normal or min_score_disaster
                                <div key={question.form_id}>
                                    {/*mico*/}
                                    {/*{question.em_3_score}*/}
                                    <Myitem noshow={question.noshow} summary={question.summary}
                                            em1={question.em_1_score}
                                            sEm1={question.em_1_meet_standard === 1 ? "Ya" : "Tidak"}

                                            audit1={question.audit_1_score}
                                            sAudit1={question.audit_1_meet_standard === 1 ? "Ya" : "Tidak"}

                                            audit2={question.audit_2_score}
                                            sAudit2={question.audit_2_meet_standard === 1 ? "Ya" : "Tidak"}

                                            em2={question.em_2_score}
                                            sEm2={question.em_2_meet_standard === 1 ? "Ya" : "Tidak"}

                                            audit3={question.audit_3_score}
                                            sAudit3={question.audit_3_meet_standard === 1 ? "Ya" : "Tidak"}

                                            em3={question.em_3_score}
                                            sEm3={question.em_3_meet_standard === 1 ? "Ya" : "Tidak"}

                                            audit4={question.audit_4_score}
                                            sAudit4={question.audit_4_meet_standard === 1 ? "Ya" : "Tidak"}

                                        // todo: check -> for normal stage
                                        //       audit3={question.audit_3_score}
                                        //       sAudit3={question.audit_3_meet_standard === 1 ? "Ya" : "Tidak"}
                                            max="5" sMax={question.min_score_normal > 0 ? "Ya" : "Tidak"}
                                            link={`/em1/${question.form_id}/${schoolid}`}
                                            appType={appType}
                                    > </Myitem>
                                </div>
                            );
                        })
                    }

                    {/* all stage for auditor, viewerpusat, administrator*/}
                    {data && schoolInfo && (role == "auditor" || role == "viewerpusat" || role == "administrator") &&
                        data.data.map((question) => {
                            return (
                                // this is depends on stage and role.
                                // check again for sra, min_score_normal or min_score_disaster
                                <div key={question.form_id}>

                                    {appType === "LPLRA" &&
                                        <Myitem noshow={question.noshow} summary={question.summary}
                                                em1={question.em_1_score}
                                                sEm1={question.em_1_meet_standard === 1 ? "Ya" : "Tidak"}
                                                audit1={question.audit_1_score}
                                                sAudit1={question.audit_1_meet_standard === 1 ? "Ya" : "Tidak"}
                                                audit2={question.audit_2_score}
                                                sAudit2={question.audit_2_meet_standard === 1 ? "Ya" : "Tidak"}
                                                em2={question.em_2_score}
                                                sEm2={question.em_2_meet_standard === 1 ? "Ya" : "Tidak"}
                                                audit3={question.audit_3_score}
                                                sAudit3={question.audit_3_meet_standard === 1 ? "Ya" : "Tidak"}

                                                em3={question.em_3_score}
                                                sEm3={question.em_3_meet_standard === 1 ? "Ya" : "Tidak"}
                                                audit4={question.audit_4_score}
                                                sAudit4={question.audit_4_meet_standard === 1 ? "Ya" : "Tidak"}

                                                max="5" sMax={question.min_score_normal > 0 ? "Ya" : "Tidak"}
                                                link={`/em1/${question.form_id}/${schoolid}`}
                                                appType={appType}
                                        >

                                        </Myitem>
                                    }
                                    {appType !== "LPLRA" &&
                                        <Myitem noshow={question.noshow} summary={question.summary}
                                                em1={question.em_1_score}
                                                sEm1={question.em_1_meet_standard === 1 ? "Ya" : "Tidak"}
                                                audit1={question.audit_1_score}
                                                sAudit1={question.audit_1_meet_standard === 1 ? "Ya" : "Tidak"}
                                                audit2={question.audit_2_score}
                                                sAudit2={question.audit_2_meet_standard === 1 ? "Ya" : "Tidak"}
                                                em2={question.em_2_score}
                                                sEm2={question.em_2_meet_standard === 1 ? "Ya" : "Tidak"}
                                                audit3={question.audit_3_score}
                                                sAudit3={question.audit_3_meet_standard === 1 ? "Ya" : "Tidak"}

                                                em3={"-"}
                                                sEm3={"-"}
                                                audit4={"-"}
                                                sAudit4={"-"}

                                                max="5" sMax={question.min_score_normal > 0 ? "Ya" : "Tidak"}
                                                link={`/em1/${question.form_id}/${schoolid}`}
                                                appType={appType}
                                        >
                                        </Myitem>
                                    }
                                </div>
                            );
                        })
                    }
                </Stack>
                <Button variant="contained" sx={{borderRadius: "0px", boxShadow: "none"}}
                        onClick={() => history.goBack()}>Kembali</Button>
            </div>
        </div>
    );
}

// http://localhost:3001/questions/1
