import React, {useEffect, useState} from 'react';
import axios from "axios";

import "./SchoolsList.css";
import {useHistory} from "react-router-dom";

import useUser from "../../user/use";
import {topTitle, getDiv} from "./SchoolList3";


function SchoolsList(props) {
  const [listSchools, setListSchools] = useState(null);
  const [listSchoolStages, setListSchoolStages] = useState(null);
  const history = useHistory();

  const [update, setUpdate] = useState(false);

  const appType = process.env.REACT_APP_APPTYPE;

  const {
    state: {userId, role, token},
  } = useUser();

  let titleInstrument = "";
  let linkString = "";

  switch (role) {
    case "operator":
      titleInstrument = "Peserta";
      linkString = "schoolcategories2";
      break;
    case "auditor":
      titleInstrument = "Auditor";
      linkString = "schoolcategories2";
      break;
    // new
    case "province":
      titleInstrument = "Province";
      linkString = "schoolcategories2";
      break;
    case "viewerpusat":
      titleInstrument = "Pusat";
      linkString = "schoolcategories2";
      break;
    case "administrator":
      titleInstrument = "Administrator";
      linkString = "schoolcategories2";
      break;
  }

  useEffect(() => {
    // TODO: get all schools --> get authorize schools
    // const data = await axios.get(queryString, {params: {userId: userId, role: role}});

    let typeEMAudit = "2";
    if (appType === "LPLRA") {
      typeEMAudit = "3";
    }
    const url = process.env.REACT_APP_SERVER + "/schoolsmulti?"+"typeemaudit="+typeEMAudit;
    axios.get(url, {params: {userId: userId, role: role}})
      .then(
        response => {
          console.log(response.data.data);
          setListSchools(response.data.data);
          // how if add info for each list schools here?
          // console.log(response.data.data);
        }
      )
      .catch(err => {
        console.log(err);
      });
    setUpdate(false);
  }, [role, userId, update]);


  // console.log(typeof setUpdate)

  return (
    <div className={"schoolslist"}>
      {topTitle("Beranda")}
      {getDiv(userId, role, listSchools, linkString, appType, setUpdate)}
    </div>
  )

}

export default SchoolsList;