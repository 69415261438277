import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";

import axios from "axios";
import DOMPurify from "dompurify";

import DialogX from "./DialogX";
import {FormControl, FormControlLabel, Paper, Radio, RadioGroup, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Divider from '@mui/material/Divider';
import { Card, CardContent } from '@mui/material';

// import {Em1Attachment} from "../pages/EM1Attachment/EM1Attachment";
import Attachment from "./Attachment";

function QuestionComponent({
                             formid,
                             schoolid,
                             readonly,
                             descriptionShow,
                             dataSource,
                             descriptionRequired,
                             attachmentRequired,
                             showEditToggle = false
                           }) {
  const baseURL = process.env.REACT_APP_SERVER;
  let history = useHistory();

  const [dataForm, setDataForm] = useState(null);
  const [dataSchool, setDataSchool] = useState(null);
  const [dataAnswer, setDataAnswer] = useState(null);

  // hasil perhitungan
  const [score, setScore] = useState(0);  // score (EM1, EM2, Audit1, Audit2 depend on dataSource)
  const [meetStandard, setMeetStandard] = useState(0);  // 1: memenuhi, 0: tidak memenuhi
  const [standardMinimum, setStandardMinimum] = useState(-1);  // minimum choice standard that need to be meet.
  // depend on schoolid --> disaster_prone.
  // TODO: change field name in database when ready (minStandard --> meetStandard) in EM1, EM2, Audit1, Audit2, or not (in reporting instead).

  const [id, setId] = useState(-1); // id = em1id, em2id, audit1id, audit2id
  const [selectedChoice, setSelectedChoice] = useState(-1);  // selected choice dari user. number 0-4. 0 not yet
  const [explanation, setExplanation] = useState(""); // jawaban penjelasan dari user
  // FIXME: attachment ==> calculate
  const [attachmentCount, setAttachmentCount] = useState(-1);  // jumlah attachment

  // edit & batal edit
  const [internalReadOnly, setInternalReadOnly] = useState(readonly);
  const [showEditButton, setShowEditButton] = useState(showEditToggle);
  // const [ubahButton, setUbahButton] = useState(readonly);

  // both below data for using with attachment
  const [selectedChoiceDatabase, setSelectedChoiceDatabase] = useState(-1); // only change 1. get from database. 2. saving to database.
  const [explanationDatabase, setExplanationDatabase] = useState(""); // only change 1. get from database. 2. saving to database.

  /* Dialog Alert */
  const [open, setOpen] = useState(false);
  const [dialogDescription, setDialogDescription] = useState("");
  const [dialogTitle, setDialogTitle] = useState("Pertanyaan");
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // fetch formid --> forminfo
  useEffect(() => {
    axios.get(baseURL + "/form?formid=" + formid)
      .then(response => {
          // console.log("dataForm:", response.data.data[0]);
          setDataForm(response.data.data[0]);
        }
      )
      .catch(err => {
        console.log(err);
      });
  }, [baseURL, formid]);

  // fetch schoolid --> schoolinfo
  useEffect(() => {
    axios.get(baseURL + "/schoolinfo?schoolid=" + schoolid)
      .then(response => {
        // console.log("dataSchool:", response.data.data[0]);
        setDataSchool(response.data.data[0]);
      })
      .catch(err => {
        console.log(err);
      });
  }, [baseURL, schoolid]);

  // TODO: field name in database EM1..Audit1: min_score_normal --> min_choice_normal
  // set standard minimum
  useEffect(() => {
    if (dataSchool && dataForm) {
      // calculate minimum standard
      if (dataSchool.disaster_prone === 1) {
        setStandardMinimum(dataForm.min_score_disaster);
      } else {
        setStandardMinimum(dataForm.min_score_normal);
      }
    }
  }, [dataSchool, dataForm]);

  // fetch em1 --> em1 choice
  // if x = "em1" --> get data from em_1
  // if x = "audit1" --> get data from audit_1
  // if x = "em2"  --> get data from em_2
  // if x = "audit2" --> get data from audit_2
  useEffect(() => {
    let tableActive = "";
    switch (dataSource) {
      case "EM1":
        tableActive = "EM1";
        break;
      case "AUDIT1":
        tableActive = "AUDIT1";
        break;
      case "AUDIT2":
        tableActive = "AUDIT2";
        break;
      case "EM2":
        tableActive = "EM2";
        break;
      case "AUDIT3":
        tableActive = "AUDIT3";
        break;
      case "EM3":
        tableActive = "EM3";
        break;
      case "AUDIT4":
        tableActive = "AUDIT4";
        break;
    }
    const queryString = `${baseURL}/answer?datasource=${tableActive}&schoolid=${schoolid}&formid=${formid}`;
    // console.log(queryString);
    axios.get(queryString)
      .then(response => {
        // console.log("dataAnswer:", response.data.data[0]);
        setDataAnswer(response.data.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [baseURL, schoolid, formid, dataSource]);

  // spread dataAnswer
  useEffect(() => {
    if (dataAnswer) {
      if (!dataAnswer.selected_choice) {
        setSelectedChoice(0);
      } else {
        setSelectedChoice(dataAnswer.selected_choice);
      }
      setExplanation(dataAnswer.explanation);
      setId(dataAnswer.id);
      // below for using with changing attachment
      setSelectedChoiceDatabase(dataAnswer.selected_choice);
      setExplanationDatabase(dataAnswer.explanation);
    }
  }, [dataAnswer]);

  const calculateScore = (attachmentcount, explanation, selectedchoice) => {
    // ketika ketiga data lengkap baru dihitung
    let factor = 1;
    let score = 0;
    // check if attachment > 0.
    if ((attachmentcount === 0) && attachmentRequired) {
      factor = 0;
    }
    // if explanation empty. !should fill.
    if ((explanation.trim().length === 0) && descriptionRequired) {
      factor = 0;
    }

    switch (selectedchoice ? selectedchoice.toString() : "0") {
      case "0":
        score = 0 * factor;
        break;
      case "1":
        score = 0 * factor;
        break;
      case "2":
        score = 1 * factor;
        break;
      case "3":
        score = 3 * factor;
        break;
      case "4":
        score = 5 * factor;
        break;
      default:
        score = 0;
        break;
    }
    return score;
  };

  // calculate score here & standard minimum
  useEffect(() => {
    if ((dataForm) && (dataSchool) && (dataAnswer) && (standardMinimum >= 0)) {
      // check selectedChoice >= minStandard Disaster or Not Disaster
      if (selectedChoice >= standardMinimum) {
        setMeetStandard(1);
      } else {
        setMeetStandard(0);
      }
      setScore(calculateScore(attachmentCount, explanation, selectedChoice));
    }
  }, [selectedChoice, explanation, attachmentCount]);

  useEffect(() => {
    if ((dataForm) && (dataSchool) && (dataAnswer) && (standardMinimum >= 0)) {
      // rekam di sini saja. karena score aman jika disave.
      const score2 = calculateScore(attachmentCount, explanationDatabase, selectedChoiceDatabase);
      const sendData = {
        "datasource": dataSource, "schoolid": schoolid, "formid": formid,
        "edited": 1, "score": score2
      };
      // each time change in attachment, change score and edited status.
      axios.patch(baseURL + "/answer/score", sendData)
        .then(response => {
          // console.log(response.data);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [attachmentCount]);
  // FIXME: how to make sure, this saving is only when attachmentcount has already check. not only score
  //  when choice change (not saved yet), and changing score. will it be save?

  const handleChangeChoice = (event) => {
    // setChoiceNumber(event.target.value);
    switch (event.target.value) {
      case "1":
        setSelectedChoice(1);
        break;
      case "2":
        setSelectedChoice(2);
        break;
      case "3":
        setSelectedChoice(3);
        break;
      case "4":
        setSelectedChoice(4);
        break;
      default:
        setSelectedChoice(0);
    }
  };

  const handleChangeText = (event) => {
    setExplanation(event.target.value);
  };

  const handleSave = () => {
    console.log("saving data");

    // saving data to EM1, EM2, Audit1, Audit2
    // with calculation score
    const sendData = {
      "datasource": dataSource, "schoolid": schoolid, "formid": formid,
      "selectedchoice": selectedChoice, "explanation": explanation,
      "score": score, "meetstandard": meetStandard, edited: 1
    };
    // each time data saved (change or not), change status edited to 1.
    axios.patch(baseURL + "/answer", sendData)
      .then(response => {
        // console.log(response.data);
        // below for using with changing attachment
        setSelectedChoiceDatabase(selectedChoice);
        setExplanationDatabase(explanation);
      })
      .catch(err => {
        console.log(err);
      });

    // saving data to reporting table
  };

  // const CustomInput = React.forwardRef(({ value, ...props }, ref) => {
  //   return (
  //     <div
  //       ref={ref}
  //       {...props}
  //       dangerouslySetInnerHTML={{ __html: value }}
  //       contentEditable={true}
  //       suppressContentEditableWarning={true}
  //     />
  //   );
  // });

  function replaceNewlinesWithBreaks(content) {
    return content.replace(/\n/g, '<br>');
  }

  return (
    <div>
      {(dataForm && dataAnswer && dataSchool && (selectedChoice >= 0) &&
        <Paper elevation={3} sx={{padding: 3, marginTop: 2, paddingTop: 1}}>
          {/*<Typography variant="h5">Nilai {dataSource}: {score}</Typography>*/}
          <Typography variant="h5">Nilai: {score}</Typography>
          <Typography
            variant="h6">{(meetStandard === 1) ? "Memenuhi Standard" : "Tidak Memenuhi Standard"}</Typography>
          <Divider/>
          {/*{descriptionShow &&*/}
          {/*  <TextField*/}
          {/*    id="outlined-multiline-static"*/}
          {/*    multiline*/}
          {/*    value={dataForm.question}*/}
          {/*    fullWidth*/}
          {/*    variant="standard"*/}
          {/*    // InputProps={{*/}
          {/*    //   disableUnderline: true,*/}
          {/*    // }}*/}
          {/*    InputProps={{*/}
          {/*      inputComponent: CustomInput,*/}
          {/*      disableUnderline: true,*/}
          {/*    }}*/}
          {/*  />}*/}
          {descriptionShow && (
            <Card elevation={0}>
              <CardContent>
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(replaceNewlinesWithBreaks(dataForm.question)),
                  }}
                />
              </CardContent>
            </Card>
          )}
          <FormControl>
            <Box sx={{
              pt: 1,
              pl: 10,
              pr: 10,
              pb: 2,
            }}>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={selectedChoice.toString()}
                onChange={!internalReadOnly ? handleChangeChoice : () => {
                }}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio/>}
                  label={dataForm.choice1}
                  disabled={dataForm.choice1 === "---"}
                />
                <FormControlLabel
                  value="2"
                  control={<Radio/>}
                  label={dataForm.choice2}
                  disabled={dataForm.choice2 === "---"}
                />
                <FormControlLabel
                  value="3"
                  control={<Radio/>}
                  label={dataForm.choice3}
                  disabled={dataForm.choice3 === "---"}
                />
                <FormControlLabel
                  value="4"
                  control={<Radio/>}
                  label={dataForm.choice4}
                  disabled={dataForm.choice4 === "---"}
                />
              </RadioGroup>
            </Box>
          </FormControl>
          {/*{descriptionShow &&*/}
          {/*  <TextField*/}
          {/*    id="outlined-multiline-static"*/}
          {/*    multiline*/}
          {/*    value={dataForm.description ? dataForm.description : ""}*/}
          {/*    fullWidth*/}
          {/*    variant="standard"*/}
          {/*    InputProps={{*/}
          {/*      disableUnderline: true,*/}
          {/*    }}*/}
          {/*  />*/}
          {/*}*/}
          {descriptionShow && (
            <Card elevation={0}>
              <CardContent>
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(replaceNewlinesWithBreaks(dataForm.description ? dataForm.description : "")),
                  }}
                />
              </CardContent>
            </Card>
          )}
          <Box
            component="form"
            sx={{
              pt: 3,
              pb: 3,
              pl: 5,
              pr: 5,
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="outlined-multiline-static"
              label=""
              fullWidth
              multiline
              rows={6}
              value={explanation}
              onChange={!internalReadOnly ? handleChangeText : () => {
              }}
              variant="outlined"
              focused
            />
          </Box>
          <Stack spacing={2} direction="row">
            {!internalReadOnly &&
              <Button variant="contained" color="success"
                      sx={{borderRadius: "0px", boxShadow: "none"}}
                      onClick={() => {
                        handleSave();
                        setDialogDescription("Data sudah berhasil disimpan");
                        setOpen(true);
                      }}>
                Simpan
              </Button>
            }
            {showEditButton &&
              <Button variant="contained" color="success"
                      sx={{borderRadius: "0px", boxShadow: "none"}}
                      onClick={() => {
                        setInternalReadOnly(false);
                        // setUbahButton(false);
                      }}>Ubah</Button>
            }
            {showEditButton &&
              <Button variant="contained" color="success"
                      sx={{borderRadius: "0px", boxShadow: "none"}}
                      onClick={() => {
                        setInternalReadOnly(readonly);
                        // setUbahButton(true);
                        // show dialog are you sure
                        // if yes. reset to previous content
                        // including delete attachment and remove description
                        // kalau batal edit (setelah edit di klik sebelumnya, maka data akan diambil lagi dari sebelumnya
                      }}>Batal Ubah</Button>
            }
            {/*<Button variant="contained" onClick={() => history.goBack()}>Kembali</Button>*/}
          </Stack>
          <Box sx={{
            marginTop: 5,
            marginBottom: 0
          }}>
            <Divider/>
          </Box>
          {(dataForm && dataAnswer && dataSchool) &&
            <div>
              <Attachment id={id} datasource={dataSource} schoolid={schoolid}
                          readonly={internalReadOnly}
                          setAttachmentCount={setAttachmentCount}></Attachment>
            </div>
          }
        </Paper>
      )}
      <DialogX open={open} onClose={handleClose} description={dialogDescription} title={dialogTitle}/>
    </div>
  );
}

export default QuestionComponent;